import * as $ from "jquery";
window.addEventListener('load', function () {
    setInterval(function () {
        autoSaveText();
    }, 1500);
});
export var autoSaveInit = function () {
    var autosaveElements = document.querySelectorAll('.autosave');
    autosaveElements.forEach(function (element) {
        element.addEventListener('keyup', function (e) {
            element.classList.add('autosaveactive');
        });
    });
};
export var autoSave = function () {
    var autoSave = document.querySelectorAll(".autosave");
    autoSave.forEach(function (element) {
        fetch('autosave.asmx/AutoSaveField', {
            method: 'POST',
            body: JSON.stringify({
                ControlClientID: element.id,
                ID: recordID().toString(),
                TextValue: this.value
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) { return response.text(); })
            .then(function (data) {
            //console.log(data);
            element.classList.remove("autosaveactive");
        })
            .catch(function (error) {
            console.error('Error:', error);
        });
    });
};
export var autoSaveText = function () {
    var autoSaveText = document.querySelectorAll('.autosaveactive');
    autoSaveText.forEach(function (element) {
        fetch('autosave.asmx/AutoSaveText', {
            method: 'POST',
            body: JSON.stringify({
                ControlClientID: element.id,
                ID: recordID().toString(),
                AutoSaveText: element.value
            }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(function (response) { return response.json(); })
            .then(function (data) {
            //console.log(data);
            element.classList.remove('autosaveactive');
        })
            .catch(function (error) {
            alert('Failed: ' + error);
        });
    });
};
export var autoSaveClear = function (AutoSaveGroup) {
    var autoSaveActive = document.querySelectorAll('.autosaveactive');
    autoSaveActive.forEach(function (element) {
        element.classList.remove('autosaveactive');
    });
    var autoSaveGroup = document.querySelectorAll('.' + AutoSaveGroup);
    autoSaveGroup.forEach(function (element) {
        fetch('autosave.asmx/AutoSaveClear', {
            method: 'POST',
            body: JSON.stringify({
                ControlClientID: element.id,
                ID: recordID().toString()
            }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(function (response) { return response.json(); })
            .then(function (data) {
            //    console.log(data);
        })
            .catch(function (error) {
            alert('Failed: ' + error);
        });
    });
};
export var autoSaveCheck = function () {
    var exists = false;
    var autoSave = document.querySelectorAll('.autosave');
    autoSave.forEach(function (element) {
        if (element.style.display !== 'none') {
            fetch('autosave.asmx/AutoSaveCheck', {
                method: 'POST',
                body: JSON.stringify({
                    ControlClientID: element.id,
                    ID: recordID().toString()
                }),
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(function (response) { return response.json(); })
                .then(function (data) {
                if (data && data.AutoSaveTextExists === 'Y') {
                    element.classList.add('autosaveprompt');
                    exists = true;
                }
            })
                .catch(function (error) {
                alert('Failed: ' + error);
            });
        }
    });
    return exists;
};
export var autoSavePrompter = function () {
    var exists = false;
    var showDialog = true;
    var autoSavePrompt = document.querySelector('.autosaveprompt');
    if (autoSavePrompt && autoSavePrompt.style.display !== 'none') {
        exists = true;
        fetch('autosave.asmx/AutoSaveCheckText', {
            method: 'POST',
            body: JSON.stringify({
                ControlClientID: autoSavePrompt.id,
                ID: recordID().toString()
            }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(function (response) { return response.json(); })
            .then(function (data) {
            if (data && data.AutoSaveText !== undefined) {
                if (autoSavePrompt.value === data.AutoSaveText) {
                    showDialog = false;
                }
                else {
                    var txtCurrent = document.querySelector('#txtCurrent');
                    txtCurrent.value = autoSavePrompt.value;
                    var txtAutoSaveText = document.querySelector('#txtAutoSaveText');
                    txtAutoSaveText.value = data.AutoSaveText;
                    showDialog = true;
                }
                autoSavePrompt.classList.remove('autosaveprompt');
            }
            if (showDialog) {
                if (!document.getElementById('dialogAutoSave')) {
                    var dialogAutoSave = document.createElement('div');
                    dialogAutoSave.id = 'dialogAutoSave';
                    document.body.appendChild(dialogAutoSave);
                    var dialogOptions = {
                        autoOpen: false,
                        modal: true,
                        resizable: false,
                        width: 650,
                        height: 400,
                        zIndex: 100002,
                    };
                    $(dialogAutoSave).dialog(dialogOptions);
                    var continueButton = document.createElement('button');
                    continueButton.textContent = 'Continue';
                    continueButton.onclick = function () {
                        var obj = JSON.parse(data.d);
                        var control = document.getElementById(obj.ControlClientID);
                        var txtCurrent = document.getElementById('txtCurrent');
                        control.value = txtCurrent.value;
                        if (!autoSavePrompter()) {
                            $(dialogAutoSave).dialog('close');
                        }
                    };
                    $(dialogAutoSave).dialog('option', 'buttons', {
                        'Continue': continueButton
                    });
                    $('.ui-dialog').css('z-index', 100003);
                    $('.ui-widget-overlay').css('z-index', 100002);
                    $(dialogAutoSave).dialog('open');
                    $(dialogAutoSave).addClass('btn btn-primary');
                }
            }
            else {
                if (!autoSavePrompter() && document.getElementById('dialogAutoSave')) {
                    $(dialogAutoSave).dialog('close');
                }
            }
        })
            .catch(function (error) {
            alert('Failed: ' + error);
        });
    }
    return exists;
};
var recordID = function () {
    var autoSaveType = document.getElementById('autoSaveType').value;
    var autoSaveID = document.getElementById('autoSaveID').value;
    var url = window.location.href;
    if (autoSaveType === "client") {
        if (url.indexOf("client") !== -1) {
            return Number(getQSP(removeHash(url), "victimid"));
        }
        else {
            return 0;
        }
    }
    else if (autoSaveType === "child") {
        if (url.indexOf("childid") !== -1) {
            return Number(getQSP(removeHash(url), "childid"));
        }
        else {
            return 0;
        }
    }
    else if (autoSaveType === "editCaseNote") {
        return autoSaveID;
    }
    return 0;
};
var getQSP = function (sURL, sName) {
    var theItmToRtn = "";
    var theSrchStrg = location.search;
    if (sURL)
        theSrchStrg = sURL;
    var sOrig = theSrchStrg;
    theSrchStrg = theSrchStrg.toUpperCase();
    sName = sName.toUpperCase();
    theSrchStrg = theSrchStrg.replace("?", "&");
    theSrchStrg = theSrchStrg + "&";
    var theSrchToken = "&" + sName + "=";
    if (theSrchStrg.indexOf(theSrchToken) != -1) {
        var theSrchTokenLth = theSrchToken.length;
        var theSrchTokenLocStart = theSrchStrg.indexOf(theSrchToken) + theSrchTokenLth;
        var theLocOfNextAndSign = theSrchStrg.indexOf("&", theSrchTokenLocStart);
        theItmToRtn = unescape(sOrig.substring(theSrchTokenLocStart, theLocOfNextAndSign));
    }
    return unescape(theItmToRtn);
};
var removeHash = function (str) {
    if (str.endsWith('#'))
        return str.slice(0, -1);
    else if (str.indexOf('#') != -1)
        return str.substring(0, str.indexOf('#') - 1);
    else
        return str;
};
